<template>
  <v-text-field
    v-model="currentValue"
    :rules="[rule]"
    :label="label"
    suffix="CHF"
  ></v-text-field>
</template>
<script>
export default {
  props: ['label', 'value'],
  data() {
    return {
      currentValue: '',
      firstFormat: true,
      rule: (value) => {
        if (!value) {
          return true;
        }
        const pattern = /^\d*\.?\d*$/;
        return pattern.test(value) || 'Betrag bitte als Dezimalzahl eingeben';
      },
    };
  },
  watch: {
    value() {
      if (this.firstFormat) {
        this.currentValue = this.value
          ? Number.parseFloat(this.value).toFixed(2)
          : '';
        this.firstFormat = false;
      }
    },
    currentValue() {
      this.$emit('input', this.currentValue);
    },
  },
  created() {
    this.currentValue = this.value
      ? Number.parseFloat(this.value).toFixed(2)
      : '';
  },
};
</script>
